<template>
  <q-page>
    <div class="layout-padding">
      <div v-if="!vehicleGroups" class="loading">
        <component
          :is="spinnerName"
          size="64"
          :color="partner.hex"
          class="loader"
        />
        <p>{{ $t('loading.searching.taxi') }}</p>
      </div>
      <div v-else class="results">
        <div v-if="!vehicleGroups.length" style="display: grid; width: 100%">
          <h5 class="empty">
            {{ $t('no_taxis_found') }}
          </h5>
        </div>
        <div v-for="p in vehicleGroups" :key="p.name">
          <h4>{{ p.category_label }}</h4>
          <h6>{{ p.description }}</h6>
          <vehicle-card v-for="v in p.options" :key="v.token" :vehicle="v" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import vehicleCard from './vehicle-card'
import { MSpinnerArrow, MSpinnerGeo, MSpinnerKinto } from 'components/'
export default {
  components: { vehicleCard, MSpinnerGeo, MSpinnerArrow, MSpinnerKinto },
  data () {
    return {
      slide: null
    }
  },
  computed: {
    ...mapGetters({
      vehicleGroups: 'rideHailing/vehicleGroups',
      partner: 'partner'
    }),
    spinnerName () {
      const spinnerType = this.$store.getters.partner.spinnerType
      return `m-spinner-${spinnerType}`
    }
  },
  methods: {
    selectVehicle (vehicle) {
      this.$store.dispatch('rideHailing/setValue', { prop: 'selectedVehicle', payload: vehicle })
    },
    confirm () {
      this.$store.dispatch('rideHailing/confirmDetails')
        .then(() => {
          this.$router.push({ name: 'ondemand-ride-hailing-summary' })
        })
    },
    getResults () {
      this.$store.dispatch('rideHailing/search', true)
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px
  margin-inline auto

.loading
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

.loading h5
  text-align center
  text-transform uppercase

h4, h6
  text-align center

h4
  margin-top 20px

h6
  margin-bottom 20px

.loading
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

.loader
  width 100px
  height 100px
  margin auto
  transform translate(140%)
</style>
