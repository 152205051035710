<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <div class="row">
        <q-card
          square
          class="bg-white search-form"
        >
          <q-card-section :class="`bg-primary text-white text-uppercase`">
            {{ $tc('find.taxi') }}
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <div class="row q-col-gutter-sm">
              <div class="col-6 relative-position">
                <m-location-search
                  :value="origin"
                  :label="$t('going_from')"
                  @selected="setOrigin"
                />
              </div>
              <div class="col-6 relative-position">
                <m-location-search
                  :value="destination"
                  :label="$t('drop_off_location')"
                  @selected="setDestination"
                />
              </div>
              <div class="col-6 relative-position">
                <m-date-field :value="time" :min="minPickup" :label="$t('date.pick_up')" @change="setTime" />
              </div>
              <div class="col-6 relative-position">
                <m-time-field :value="time" :min="minPickup" :label="$t('time.pick_up')" @change="setTime" />
              </div>
              <div class="col-6 relative-position">
                <q-input
                  :value="passengers"
                  :min="1"
                  :max="30"
                  type="number"
                  :label="$t('number_of.passengers')"
                  @input="setPassengers"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div
        class="cta"
      >
        <m-cta-button :label="$tc('find.taxi')" :disabled="disabled" @ctaClick="getResults" />
      </div>
    </div>
    <router-view />
  </q-page>
</template>

<script type="text/javascript">
import 'styles/ondemand.styl'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import { MLocationSearch, MTimeField, MDateField, MUnifiedUsers, MCtaButton } from 'components/'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { handleErrors } from 'utils/utils'
const { addToDate, roundMinutesUp } = date

export default {
  components: {
    MTimeField,
    MDateField,
    MLocationSearch,
    MUnifiedUsers,
    MCtaButton
  },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      origin: 'rideHailing/origin',
      destination: 'rideHailing/destination',
      time: 'rideHailing/time',
      traveller: 'rideHailing/traveller',
      requester: 'rideHailing/requester',
      passengers: 'rideHailing/passengers'
    }),
    minPickup () {
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 5 }), 5)
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    },
    people: {
      get () {
        return {
          traveller: this.traveller,
          requester: this.requester
        }
      },
      set (val) {
        this.$store.dispatch('rideHailing/setRequester', val.requester)
        this.$store.dispatch('rideHailing/setTraveller', val.traveller)
      }
    }
  },
  created () {
    this.$store.dispatch('rideHailing/reset')
    this.setTime(this.minPickup)
  },
  methods: {
    setOrigin (val) {
      this.$store.dispatch('rideHailing/setLocation', { field: 'origin', location: val })
    },
    setDestination (val) {
      this.$store.dispatch('rideHailing/setLocation', { field: 'destination', location: val })
    },
    setTime (val) {
      this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: val })
    },
    setPassengers (val) {
      this.$store.dispatch('rideHailing/setValue', { prop: 'passengers', payload: parseInt(val) })
    },
    getResults () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$router.push({ name: 'ondemand-office-ride-hailing-results' })
        this.$store.dispatch('rideHailing/search', true)
          .catch((err) => {
            handleErrors(err)
          })
      } else {
        this.$q.notify({ color: 'negative', icon: 'clear', message: this.$t('taxi.validation.form') })
      }
    }
  },
  validations: {
    origin: { required },
    destination: { required },
    time: { required },
    traveller: { required }
  }
}
</script>

<style lang="stylus" scoped>

.search-form
  position relative

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px
</style>
